import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import Korpsabzeichen from '../Korpsabzeichen.png';

import Events from './Events';
import Bottles from './Bottles';
import Masks from './Masks';
import CheckBottles from './CheckBottles';
import CheckMasks from './CheckMasks';
import Picture from './Picture';

export default class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="/">
              <img src={Korpsabzeichen} alt="Korpsabzeichen"/>
              &nbsp;Atemschutzverwaltung
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/einsaetze">Einsätze</Nav.Link>
                <NavDropdown title="Prüfungen" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/pruefung-flaschen">Flaschen</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/pruefung-masken">Masken</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Geräteverwaltung" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/flaschen">Flaschen</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/masken">Masken</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <Route path="/" exact component={Picture}/>
        <Route path="/einsaetze" component={Events}/>
        <Route path="/pruefungen" component={Events}/>
        <Route path="/flaschen" component={Bottles}/>
        <Route path="/masken" component={Masks}/>
        <Route path="/pruefung-flaschen" component={CheckBottles}/>
        <Route path="/pruefung-masken" component={CheckMasks}/>
      </Router>
    );
  }
}
