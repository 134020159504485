import React, { Component } from 'react';
import Image from '../backgroundimage.jpg';
import '../image.css';

export default class Picture extends Component {
    render() {
        return(
            <img src={Image} alt="Feuerwehr Mittelberg" id="image" />
        );
    }
}