import React, { Component } from 'react';
import { Navbar, Button, Table, Modal, Form } from 'react-bootstrap';
import { Pencil, XCircle, Key } from 'react-bootstrap-icons';
import axios from 'axios';
import Moment from 'react-moment';

const Event = (props) => (
  <tr>
    <td>{props.event.id}</td>
    <td>{props.event.rfl_els_incident_id}</td>
    <td>{props.event.code}</td>
    <td>{props.event.pager_text}</td>
    <td><Moment date={props.event.date} parse="YYYY-MM-DD" format="DD.MM.YYYY" /></td>
    <td><a href="#" onClick={() => { props.getEventById(props.event.id) }}><Pencil /></a>&nbsp;&nbsp;
      <a href="#" onClick={() => { props.deleteEvent(props.event.id) }}><XCircle /></a></td>
  </tr>
)

export default class Events extends Component {
  constructor(props) {
    super(props);

    this.deleteEvent = this.deleteEvent.bind(this);
    this.getEventById = this.getEventById.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      events: [],
      event_id: "",
      event_rfl_els_incident_id: "",
      event_code: "",
      event_pager_text: "",
      event_date: "",
      method: "",
      addModalShow: false
    };
  }

  clearEvent() {
    this.setState({
      event_rfl_els_incident_id: "",
      event_code: "",
      event_pager_text: "",
      event_date: "",
      method: "",
    })
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    axios.get('https://demo-api.snelinski.at/api/events/')
      .then(response => {
        this.setState({
          events: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getEventById(id) {
    axios.get('https://demo-api.snelinski.at/api/events/id/' + id)
      .then(response => {
        this.setState({
          event_id: response.data.id,
          event_rfl_els_incident_id: response.data.rfl_els_incident_id,
          event_code: response.data.code,
          event_pager_text: response.data.pager_text,
          event_date: response.data.date,
          method: "update",
          addModalShow: true
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateEvent() {
    axios.put('https://demo-api.snelinski.at/api/events/update/' + this.state.event_id, {
      'rfl_els_incident_id': this.state.event_rfl_els_incident_id,
      'code': this.state.event_code,
      'pager_text': this.state.event_pager_text,
    })
      .then(response => {
        this.componentDidMount();
        this.listEvents();
      });
  }

  insertEvent() {
    axios.post('https://demo-api.snelinski.at/api/events/create', {
      'rfl_els_incident_id': this.state.event_rfl_els_incident_id,
      'code': this.state.event_code,
      'pager_text': this.state.event_pager_text
    })
      .then(response => {
        this.componentDidMount();
        this.listEvents();
      })
  }

  deleteEvent(id) {
    axios.delete('https://demo-api.snelinski.at/api/events/delete/' + id)
      .then(response => {
        this.setState({
          events: response.data
        });
      })
      .catch((error) => {
        this.componentDidMount();
        this.listEvents();
      });
  }

  dataEvent(e) {
    let tempData = {
      rfl_els_incident_id: this.state.rfl_els_incident_id,
      code: this.state.code,
      pager_text: this.state.pager_text,
    }

    if (this.state.method === 'update') {

    } else {

    }
    this.clearEvent();
  }

  listEvents() {
    return this.state.events.map(eventList => {
      return <Event event={eventList} deleteEvent={this.deleteEvent} getEventById={this.getEventById}
                    key={eventList.id} />;
    })
  }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });
    return (
      <div>
        <Navbar bg="light">
          <Navbar.Brand>Einsätze</Navbar.Brand>
          <Button variant="primary" size="sm" onClick={() => { this.componentDidMount();
          this.listEvents(); }}>Aktualisieren</Button>&nbsp;
          <Button variant="success" size="sm" onClick={() =>{
            if (this.state.method === 'update') {
              this.clearEvent();
            }
            this.setState({ addModalShow: true, method: 'insert' })}}>Einsatz Erstellen</Button>
        </Navbar>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>RFL Einsatz</th>
              <th>Code</th>
              <th>Alarmierungstext</th>
              <th>Datum</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {this.listEvents()}
          </tbody>
        </Table>


        <Modal
          show={this.state.addModalShow}
          onHide={addModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Einsatz</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
            <Form.Group>
                <Form.Control type="text" name="event_rfl_els_incident_id" placeholder="RFL Einsatz"
                  value={this.state.event_rfl_els_incident_id} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="code">
                <Form.Control type="text" name="event_code" placeholder="Code" value={this.state.event_code}
                  onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="pager_text">
                <Form.Control type="text" name="event_pager_text" placeholder="Alarmierungstext"
                  value={this.state.event_pager_text} onChange={this.handleChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={addModalClose}>
              Abbrechen
          </Button>
            <Button variant="primary" onClick={() => {
              if (this.state.method === 'update') {
                this.updateEvent();
                addModalClose();
                this.componentDidMount();
                this.listEvents();
              } else if (this.state.method === 'insert') {
                this.insertEvent();
                addModalClose();
                this.componentDidMount();
                this.listEvents();
              }
            }}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
