import React, { Component } from 'react';
import { Navbar, Button, Table, Modal, Form } from 'react-bootstrap';
import { Pencil, XCircle } from 'react-bootstrap-icons';
import axios from 'axios';

const Bottle = (props) => (
  <tr>
    <td>{props.bottle.id}</td>
    <td>{props.bottle.inventory_id}</td>
    <td>{props.bottle.manufacturer}</td>
    <td>{props.bottle.model}</td>
    <td>{props.bottle.year_of_manufacturing}</td>
    <td><a href="#" onClick={() => { props.getBottleById(props.bottle.id) }}><Pencil /></a>&nbsp;&nbsp;
      <a href="#" onClick={() => { props.deleteBottle(props.bottle.id) }}><XCircle /></a></td>
  </tr>
)

export default class Bottles extends Component {
  constructor(props) {
    super(props);

    this.deleteBottle = this.deleteBottle.bind(this);
    this.getBottleById = this.getBottleById.bind(this);
    this.updateBottle = this.updateBottle.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      bottles: [],
      bottle_id: "",
      bottle_inventory_id: "",
      bottle_manufacturer: "",
      bottle_model: "",
      bottle_year_of_manufacturing: "",
      method: "",
      addModalShow: false
    };
  }

  clearBottle() {
    this.setState({
      bottle_id: "",
      bottle_inventory_id: "",
      bottle_manufacturer: "",
      bottle_model: "",
      bottle_year_of_manufacturing: "",
      method: "",
    });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    axios.get('https://demo-api.snelinski.at/api/bottles/')
      .then(response => {
        this.setState({
          bottles: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBottleById(id) {
    axios.get('https://demo-api.snelinski.at/api/bottles/id/' + id)
      .then(response => {
        this.setState({
          bottle_id: response.data.id,
          bottle_inventory_id: response.data.inventory_id,
          bottle_manufacturer: response.data.manufacturer,
          bottle_model: response.data.model,
          bottle_year_of_manufacturing: response.data.year_of_manufacturing,
          method: "update",
          addModalShow: true
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateBottle() {
    axios.put('https://demo-api.snelinski.at/api/bottles/update/' + this.state.bottle_id, {
      'inventory_id': this.state.bottle_inventory_id,
      'manufacturer': this.state.bottle_manufacturer,
      'model': this.state.bottle_model,
      'year_of_manufacturing': this.state.bottle_year_of_manufacturing,
    })
      .then(response => {
        this.componentDidMount();
        this.listBottles();
      });
  }

  insertBottle() {
    axios.post('https://demo-api.snelinski.at/api/bottles/create', {
      'inventory_id': this.state.bottle_inventory_id,
      'manufacturer': this.state.bottle_manufacturer,
      'model': this.state.bottle_model,
      'year_of_manufacturing': this.state.bottle_year_of_manufacturing,
    })
      .then(response => {
        this.componentDidMount();
        this.listBottles();
      })
  }

  deleteBottle(id) {
    axios.delete('https://demo-api.snelinski.at/api/bottles/delete/' + id)
      .then(response => {
        this.setState({
          bottles: response.data
        });
      })
      .catch((error) => {
        this.componentDidMount();
        this.listBottles();
      });
  }

  listBottles() {
    return this.state.bottles.map(bottlesList => {
      return <Bottle bottle={bottlesList} deleteBottle={this.deleteBottle} getBottleById={this.getBottleById}
                   key={bottlesList.id} />;
    })
  }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });
    return (
      <div>
        <Navbar bg="light">
          <Navbar.Brand>Flaschen</Navbar.Brand>
          <Button variant="primary" size="sm" onClick={() => { this.componentDidMount();
            this.listBottles(); }}>Aktualisieren</Button>&nbsp;
          <Button variant="success" size="sm" onClick={() =>{
            if (this.state.method === 'update') {
              this.clearBottle();
            }
            this.setState({ addModalShow: true, method: 'insert' })}}>Flasche Einfügen</Button>
        </Navbar>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>#</th>
            <th>Inventar Nummer</th>
            <th>Hersteller</th>
            <th>Type</th>
            <th>Herstellungsjahr</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          {this.listBottles()}
          </tbody>
        </Table>


        <Modal
          show={this.state.addModalShow}
          onHide={addModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Flasche</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="bottle_inventory_id">
                <Form.Control type="text" name="bottle_inventory_id" placeholder="Inventar Nummer"
                              value={this.state.bottle_inventory_id} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="bottle_manufacturer">
                <Form.Control type="text" name="bottle_manufacturer" placeholder="Hersteller"
                              value={this.state.bottle_manufacturer} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="bottle_model">
                <Form.Control type="text" name="bottle_model" placeholder="Type"
                              value={this.state.bottle_model} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="bottle_year_of_manufacturing">
                <Form.Control type="text" name="bottle_year_of_manufacturing" placeholder="Herstellungsjahr"
                              value={this.state.bottle_year_of_manufacturing} onChange={this.handleChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={addModalClose}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={() => {
              if (this.state.method === 'update') {
                this.updateBottle();
                addModalClose();
                this.componentDidMount();
                this.listBottles();
              } else if (this.state.method === 'insert') {
                this.insertBottle();
                addModalClose();
                this.componentDidMount();
                this.listBottles();
              }
            }}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
