import React, { Component } from 'react';
import { Navbar, Button, Table, Modal, Form } from 'react-bootstrap';
import Moment from 'react-moment';
import axios from 'axios';

const MaskCheck = (props) => (
  <tr>
    <td>{props.maskCheck.id}</td>
    <td>{props.maskCheck.mask_id}</td>
    <td>{props.maskCheck.event_id}</td>
    <td>{props.maskCheck.visual_check}</td>
    <td>{props.maskCheck.pressure_tightness_check}</td>
    <td>{props.maskCheck.opening_high_pressure_check}</td>
    <td><Moment date={props.maskCheck.datetime_of_check} parse="YYYY-MM-DD HH:mm:ss" format="DD.MM.YYYY - HH:mm:ss" /></td>
  </tr>
)

export default class CheckMasks extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      checks: [],
      check_id: "",
      check_mask_id: "",
      check_event_id: "",
      check_visual_check: "",
      check_pressure_tightness_check: "",
      check_opening_high_pressure_check: "",
      addModalShow: false
    };
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    axios.get('https://demo-api.snelinski.at/api/masks-check/')
      .then(response => {
        this.setState({
          checks: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  insertCheck() {
    axios.post('https://demo-api.snelinski.at/api/masks-check/create', {
      'mask_id': this.state.check_mask_id,
      'event_id': this.state.check_event_id,
      'visual_check': this.state.check_visual_check,
      'pressure_tightness_check': this.state.check_pressure_tightness_check,
      'opening_high_pressure_check': this.state.check_opening_high_pressure_check
    })
      .then(response => {
        this.componentDidMount();
        this.listChecks();
      })
  }

  listChecks() {
    return this.state.checks.map(checksList => {
      return <MaskCheck maskCheck={checksList} key={checksList.id} />;
    })
  }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });
    return (
      <div>
        <Navbar bg="light">
          <Navbar.Brand>Prüfungen - Masken</Navbar.Brand>
          <Button variant="primary" size="sm" onClick={() => { this.componentDidMount();
            this.listChecks(); }}>Aktualisieren</Button>&nbsp;
          <Button variant="success" size="sm" onClick={() =>{this.setState({ addModalShow: true, method: 'insert' })}}>Überprüfung Durchführen</Button>
        </Navbar>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>#</th>
            <th>Flasche ID</th>
            <th>Einsatz ID</th>
            <th>Sichtprüfung / Reinigung</th>
            <th>Druck Dichtprüfung (bar)</th>
            <th>Öffnungsdruck Ausatemventil (bar)</th>
            <th>Durchführung der Prüfung</th>
          </tr>
          </thead>
          <tbody>
          {this.listChecks()}
          </tbody>
        </Table>


        <Modal
          show={this.state.addModalShow}
          onHide={addModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Überprüfung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="check_mask_id">
                <Form.Control type="text" name="check_mask_id" placeholder="Maske ID"
                              value={this.state.check_mask_id} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="check_event_id">
                <Form.Control type="text" name="check_event_id" placeholder="Einsatz ID"
                              value={this.state.check_event_id} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="check_visual_check">
                <Form.Control type="text" name="check_visual_check" placeholder="Sichtprüfung / Reinigung"
                              value={this.state.check_visual_check} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="check_pressure_tightness_check">
                <Form.Control type="text" name="check_pressure_tightness_check" placeholder="Druck Dichtprüfung (bar)"
                              value={this.state.check_pressure_tightness_check} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="check_opening_high_pressure_check">
                <Form.Control type="text" name="check_opening_high_pressure_check" placeholder="Öffnungsdruck Ausatemventil (bar)"
                              value={this.state.check_opening_high_pressure_check} onChange={this.handleChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={addModalClose}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={() => {
              if (this.state.method === 'insert') {
                this.insertCheck();
                addModalClose();
                this.componentDidMount();
                this.listChecks();
              }
            }}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
